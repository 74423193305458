import React from 'react';
import './App.css';
import { Analytics } from "@vercel/analytics/react"

import Navbar from './components/Navbar/Navbar';
import Footer from './components/Footer/Footer';
import AboutArtist from './components/About/AboutArtist';
import Discography from './components/Discography/Discography';
import Hero from './components/Hero/Hero';
import SpotifyLink from './components/Spotify/SpotifyLink';
import artistHeroImage from './assets/placeholder.png'; // Replace with path to artist's image
import VideoText from './components/VideoText/VideoText';
import Quote from './components/Quote/Quote';

function App() {
  return (
    <div className="flex flex-col min-h-screen bg-top" style={{ backgroundImage: `url(${artistHeroImage})` }}>
      {/* <Navbar /> */}
      <main className="flex-grow w-full h-screen container mx-auto flex flex-col items-center justify-center bg-black bg-opacity-50">
        <Analytics mode='Production' />
        <Hero />
        {/* <AboutArtist /> */}
        <SpotifyLink artistName="APAULLO" spotifyUrl="https://open.spotify.com/track/3lO7Y0mc03w5TS9s1iTeOH?si=7d7c89ffc9c543d5" instagramUrl={"https://www.instagram.com/apaullo.wav?utm_source=ig_web_button_share_sheet&igsh=ZDNlZDc0MzIxNw=="} />
      </main>
      <div className="w-full h-screen">
        <VideoText />
      </div>
      {/* <main className="flex-grow w-full h-screen container mx-auto flex flex-col items-center justify-center bg-black bg-opacity-50">
        <AboutArtist />
        <Quote />
      </main> */}
      <Footer />
    </div>
  );
}

export default App;
