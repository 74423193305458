import React from 'react';

const Footer = () => {
  return (
    <footer className="bg-gray-800 p-4 relative">
      <div className="container mx-auto text-center">
        <div className="flex flex-col md:flex-row items-center justify-center md:justify-between">
          <p className="text-white text-sm mb-2 md:mb-0">© 2024 APAULLO. All rights reserved.</p>
          <a href="https://emmettlawlor.com/contact" className="text-white text-sm">
            Created by emmettlawlor.com
          </a>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
