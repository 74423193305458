// components/Navbar.js
import React from 'react';
// import logo from '../logo.svg'; // Adjust the path as necessary

const Navbar = () => {
    return (
        <nav className="fixed w-full bg-gray-800 bg-opacity-75 z-10 shadow">
            <div className="container mx-auto flex items-center justify-between">
                <div className="flex items-center">
                    <img src="logo512.png" alt="logo" className="h-8 w-8 mr-2" />
                    <span className="text-white font-semibold text-xl tracking-tight">APAULLO</span>
                </div>
                <div>
                    <a href="#" className="text-gray-300 hover:text-white px-3 py-2 rounded-md text-sm font-medium">Home</a>
                    <a href="#" className="text-gray-300 hover:text-white px-3 py-2 rounded-md text-sm font-medium">About</a>
                </div>
            </div>
        </nav>
    );
};

export default Navbar;
