import React from 'react';

const VideoText = () => {
  return (
    <div className="flex flex-col md:flex-row items-center justify-center h-screen p-6 space-y-4 md:space-y-0 md:space-x-4 bg-gray-900">
      <div className="md:w-1/2 flex flex-col items-center justify-center text-center">
        <h1 className="text-2xl font-bold mb-2 text-white">THE SUN IS BLEEDING EP</h1>
        <p className="text-white">
          THE SUN IS BLEEDING EP is a collaborative effort and second EP with No Venom under the name of Valasa. This was released on the 27th of July with Valasas first live performance happening the day before in Waterford on the 26th. <br /><br />Talk about that for Olympic spirit.
        </p>
      </div>
      <div className="md:w-1/2 flex justify-center">
        <video
          className="w-3/4 h-auto rounded-lg"
          autoPlay
          muted
          loop
          playsInline // Add playsInline for mobile optimization
        >
          <source src="/sunisbleeding-promo.mp4" type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      </div>
    </div>
  );
};

export default VideoText;
