import React from 'react';
// Importing FontAwesome components and specific icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInstagram, faSpotify } from '@fortawesome/free-brands-svg-icons';
import { track } from '@vercel/analytics/react';

// SpotifyLink Component
// props:
//   - artistName: string
//   - spotifyUrl: string
//   - instagramUrl: string
const SpotifyLink = ({ artistName, spotifyUrl, instagramUrl }) => {
    return (
        <div className="w-full max-w-md rounded overflow-hidden shadow-lg bg-gray-800 bg-opacity-75 p-4">
            <div className="w-full px-6 py-4">
                <div className="font-bold text-center text-xl mb-2 text-white">BONFIRE out now</div>
            </div>
            <div className="flex flex-col w-full px-6 pt-2 pb-2 gap-y-4">
                <a href={spotifyUrl} onClick={() => {track('spotify')}} target="_blank" rel="noopener noreferrer" className="flex justify-center items-center w-full bg-spotify-green hover:bg-spotify-dark-green text-white font-bold py-2 px-4 rounded">
                    <FontAwesomeIcon icon={faSpotify} className="mr-2" /> Listen to {artistName} on Spotify
                </a>
                <a href={instagramUrl} onClick={() => {track('instagram')}} target="_blank" rel="noopener noreferrer" className="flex justify-center items-center w-full bg-[#E1306C] hover:bg-[#C13584] text-white font-bold py-2 px-4 rounded">
                    <FontAwesomeIcon icon={faInstagram} className="mr-2" /> Follow {artistName} on Instagram
                </a>
            </div>
        </div>
    );
};

export default SpotifyLink;
