// components/Hero.js
import React from 'react';
import artistHeroImage from '../../assets/placeholder.png'; // Replace with path to artist's image

const Hero = () => {
  return (
    <div className="flex justify-center items-center w-full relative bg-transparent">
      {/* <div className="max-w-full absolute inset-0 bg-black opacity-50"></div> */}
      <div className="relative text-center py-12">
        <h1 className="text-5xl text-white font-bold">APAULLO</h1>
        <p className="text-xl text-gray-300 mt-4">APAULLO is a visionary artist mixing alternative with rap and R&B.</p>
      </div>
    </div>
  );
};

export default Hero;
